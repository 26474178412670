import { Container, Grid, Typography } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CheckList from "../components/CheckList";
import EnquiryForm from "../components/EnquiryForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  businessLoanPageHeroContainer: {
    backgroundColor: teal[50]
  },
  businessLoanPageTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const BusinessLoanPage = () => {
  const classes = useStyles();
  const title = "Business Loan Quote";
  const description =
    "Give your business the cash injection it needs. Our experts can help you find the lowest rate business loans available.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.businessLoanPageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography
                className={classes.businessLoanPageTitle}
                variant="h1"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <CheckList
                points={[
                  "Money in as little as 48 hours",
                  "Borrow over 1 month - 5 years",
                  "No fees",
                  "No restriction on the use of funds"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <EnquiryForm
                type="BUSINESS_LOAN"
                steps={["Your Details", "Business Details", "Loan Details"]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default BusinessLoanPage;
